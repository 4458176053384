import * as React from "react"


// import "../styles/main.css"
// import "../styles/custom.css"
// import "../styles/font.css"
// import "../styles/global.css"
// import "../styles/dropdown.css"
// import "../styles/sideNavMenu.css"
// import "../styles/sideNavGlossary.css"
// import "../styles/sideNavUser.css"
// import "../styles/sideNavSchool.css"
// import "../styles/headerShrink.css"
// import "../styles/slider.css"


import Layout from "../components/Layout"


const IndexPage = () => {
  return (
    <Layout pageTitle="Smart Admissions Service">

    </Layout>
  )
}

export default IndexPage
